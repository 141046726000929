<template>
    <div>
        <!-- <van-nav-bar title="报名记录" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" @load="onLoad(page)">
                <div v-if="list.length" class="block">
                    <div class="new_card" v-for="item in list" :key="item.index">
                        <div class="new_card_on">
                            <div class="new_card_on_lt"><img style="width:100%;height:100%" :src="item.img ? item.img : morenurl" /></div>
                            <div class="new_card_on_rt">
                                <div class="new_card_on_rt_cd1">{{item.factory_name ? item.factory_name : ''}}</div>
                                <div class="new_card_on_rt_cd2">{{item.job_name}}</div>
                                <div class="new_card_on_rt_cd3">
                                    <div class="new_card_on_rt_cd3_lt"><img style="width:100%;height:100%" :src="time_log" /></div>
                                    <div class="new_card_on_rt_cd3_rt">面试时间：{{item.pending_interview_time}}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div v-for="item in list" :key="item.index" class="card">
                        <div :class="['displayFlex', item.job_is_close ? 'hide' : '']" style="align-items: center;" @click="item.job_is_close ? '' : goDetails(item.job_id)">
                            <img class="logo" :src="item.img ? item.img : morenurl" />
                            <div class="info">
                                <div class="displayFlex justifyContentBetten" style="margin-bottom: 0.1rem;">
                                    <div class="displayFlex">
                                        <div class="com_name">{{item.factory_short_name ? item.factory_short_name : item.factory_name}}</div>
                                    </div>
                                    <div class="wage">{{item.wage ? item.wage+'元/小时' : item.salary+'元/月' }}</div>
                                </div>
                                <div class="displayFlex justifyContentBetten" style="margin-bottom: 0.1rem;">
                                    <div class="job_name">{{item.job_name}}</div>
                                    <div class="job_name" style="color: #999;">{{item.apply_time}}</div>
                                </div>
                                <div class="text" style="font-size:10px" v-if="item.wage_is_change">
                                    工价已变动！
                                </div>
                            </div>
                        </div>
                        <div class="none" v-if="item.job_is_close">
                            <div class="text">
                                职位已失效
                            </div>
                            <div class="delete" @click="Delete(item.track_id)">
                                <img src="@/assets/img/delete.png" alt="">
                                移除
                            </div>
                        </div>
                    </div> -->
                </div>
                <div v-else>
                    <div class="null-data">
                        <div>
                            <img :src="src+'null_index.png'" />
                            <div>暂无面试记录</div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>

        <!-- -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/index.css'
import config from "@/utils/config.js";
// import { getApplyList, deleteApply } from "@/api/home";
import { Toast } from 'vant';
export default {
    data() {
        return {
            src: `${config.ossImageUrl}`,
            morenurl: require('../../assets/morencaifeng.png'),
            no_img: require('../../assets/img/zanwujilu.png'),
            time_log: require('../../assets/img/shizhong.png'),
            list: [],
            page: 1, //请求第几页
            total: 0, //总共的数据条数
            loading: false,
            finished: false,
            refreshing: true,
        };
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        this.getList()
    },
    methods: {
        gobanck() {
            this.$router.go(-1);
        },
        // 跳转到详情
        goDetails(id) {
            this.$router.push({
                path: "/recruitmentDetails",
                query: { rid: id, type: 'apply' },
            });
        },
        // 删除报名记录
        Delete(id) {
            var data = {
                team_id: localStorage.getItem('team_id'),
                track_id: id
            }
            this.Request('client/apply', 'delete', data).then((res) => {
                if (res.status == 0) {
                    this.list.filter((v, i) => {
                        if (v.track_id == id) {
                            this.list.splice(i, 1)
                            Toast.success('操作成功');
                        }

                    })
                }
            })
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getList();
        },
        onLoad(page) {
            this.finished = false;
            //this.loading = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.getList()
            if (page != 1) {
                this.getList(page);
            }
        },
        // 调接口，获取列表数据
        getList(page = 1) {
            if (page == 1) this.list = [];
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
            }
            this.$store.state.refreshList = false
            var data = {
                team_id: localStorage.getItem('team_id'),
                limits: 20,
                page: page,
            }
            this.Request('client/pending_interview', 'get', data).then((res) => {
                if (res.status == 0) {
                    //this.list = res.data;
                    this.page++;
                    let resultList = res.data.list

                    this.list = this.list.concat(
                        resultList
                    );
                    this.loading = false;
                    if (this.list.length >= res.data.count) {
                        this.finished = true;
                    }

                }
            })
        },

    }
};
</script>
<style scoped>
.new_card_shixiao_on_wenzi {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: #c1c1c1;
    text-align: center;
    position: absolute;
    right: 30px;
    bottom: 30px;
}
.new_card_shixiao_on_text {
    background-color: #e5e5e5;
    color: #999999;
    width: 21%;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    font-size: 16px;
    transform: rotate(45deg);
    top: 22px;
    right: -36px;
}
.new_card_on_rt {
    width: calc(100% - 205px);
    height: 140px;
}
.new_card_on_rt_cd1 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 10px;
    color: #1a1a1a;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.new_card_on_rt_cd2 {
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: left;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.new_card_on_rt_cd3 {
    width: 100%;
    height: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.new_card_on_rt_cd3_lt {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
}
.new_card_on_rt_cd3_rt {
    width: calc(100% - 55px);
    height: 25px;
    line-height: 25px;
    text-align: left;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0abbb9;
    margin-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.new_card_on_lt {
    width: 180px;
    height: 140px;
}
.new_card_on {
    width: calc(100% - 60px);
    height: 140px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.new_card {
    width: 100%;
    height: 200px;
    background: #ffffff;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.new_card_shixiao {
    width: 100%;
    height: 200px;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    left: 0;
    top: 0;
    z-index: 10;
}
.new_card_shixiao_on {
    width: 100%;
    height: 200px;
    position: relative;
    background: rgba(255, 255, 255, 0);
    overflow: hidden;
}
/* input的输入颜色 */
::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}
.com_name {
    font-size: 28px;
}
.job_name {
    font-size: 26px;
}
/* 头部固定 */
.top {
    position: fixed;
    width: 100%;
    z-index: 2;
    /* margin-top: 90px; */
}

/* 列表里的搜索框 */
.search {
    padding: 30px;
    margin-top: -2px;
    background-color: #4c94f6;
}
.search .search_text {
    display: flex;
    border: 2px solid #fff;
    border-radius: 50px;
    padding: 10px 20px;
    color: #fff;
    line-height: 40px;
}
.search .search_text .iconfont {
    color: #fff;
}
.search .search_text input {
    width: 84%;
    background: transparent;
    border: 0;
    color: #fff;
}
.search .search_text image {
    width: 32px;
    height: 32px;
    margin: 12px 20px 0 0;
}
/* 条件筛选 */
.sort {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    background-color: #fff;
    padding: 20px 60px;
}
.sort_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sort_icon > div {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin: 3px;
}

.sort_icon .sort_top {
    border-bottom: 10px solid #ccc;
}
.sort_icon .sort_bottom {
    border-top: 10px solid #ccc;
}
.screen {
    width: 40px;
    height: 40px;
}
.sort_title {
    margin-right: 10px;
}
.sort-select {
    font-weight: 700;
}
.sort .img {
    width: 40px;
    height: 40px;
}
/* 卡片 */
.block {
    position: relative;
    /* top: 110px; */
}
.hide {
    background: #fff;
    opacity: 0.3;
}
.none {
    text-align: center;
    font-size: 28px;
}
.none .text {
    position: absolute;
    line-height: 160px;
    font-weight: 700;
    text-align: center;
    width: 90%;
    top: 0;
}
.delete {
    position: absolute;
    padding: 0 30px;
    right: 0;
    position: absolute;
    bottom: 24px;
}
.delete img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
}
.card {
    background-color: #fff;
    padding: 20px 30px;
    margin-top: 20px;
    height: 120px;
    position: relative;
    align-items: center;
}
.card .logo {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}
.card .info {
    margin-left: 10px;
    width: calc(100% - 120px);
}
/* .card .info > div {
    height: 60px;
    line-height: 60px;
} */
.card .com_name {
    font-weight: 700;
    margin-right: 10px;
    max-width: 310px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.card .tips {
    border: 2px solid #666666;
    border-radius: 5px;
    color: #666666;
    padding: 0 6px;
    font-size: 20px;
    height: 32px;
    line-height: 32px;
    margin-top: 5px;
}
.card .tips.redcolor {
    border: 2px solid #ff2c2c;
    color: #ff2c2c;
}
.card .address {
    font-size: 28px;
}
.card .wage {
    color: #ff2c2c;
    font-weight: 700;
    font-size: 28px;
}

/* 不展示 */
.tips.color10 {
    color: #999999;
    border-color: #999999;
}
/* 已招满 */
.tips.color20 {
    color: #27b465;
    border-color: #27b465;
}
/* 招聘中 */
.tips.color30 {
    color: #4c94f6;
    border-color: #4c94f6;
}
/* 热招 */
.tips.color40 {
    color: #f8842c;
    border-color: #f8842c;
}
/* 急招 */
.tips.color50 {
    color: #ff2c2c;
    border-color: #ff2c2c;
}

.van-pull-refresh {
    overflow: initial;
}
</style>